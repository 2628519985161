

























































import { Component, Prop, Watch } from 'vue-property-decorator';
import ErrorMessageHandlerMixin from '../../misc/ErrorMessageHandler.mixin';
import { mixins } from 'vue-class-component';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { namespace } from 'vuex-class';
import Manager from '@/models/Manager';
import { managerStoreActions } from '@/store/manager.store';

const ManagerStore = namespace('manager');

@Component({
    mixins: [validationMixin],
    validations: {
        managerCopy: {
            login: {
                required
            },
            email: {
                required
            }
        }
    }
})
export default class EditManagerComponent extends mixins(ErrorMessageHandlerMixin) {
    @Prop({ required: false, default: null })
    public manager!: Manager | null;

    @ManagerStore.Action(managerStoreActions.CREATE)
    public createManagerAction!: (manager: Manager) => Promise<Manager>;

    @ManagerStore.Action(managerStoreActions.UPDATE)
    public updateManagerAction!: (manager: Manager) => Promise<Manager>;

    public managerCopy: Manager = new Manager();
    public isLoading: boolean = false;

    private created() {
        if (!this.manager) {
            this.managerCopy = Manager.parseFromObject({ status: true });
            return;
        }
        this.managerCopy = Manager.parseFromObject({ ...this.manager });
    }

    public async saveData() {
        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }

        this.isLoading = true;
        if (this.manager?.id) {
            await this.updateManager();
        } else {
            await this.createManager();
        }
    }

    private async createManager() {
        try {
            await this.createManagerAction(this.managerCopy);
            this.dismiss(true);
            this.$notifySuccessSimplified('MANAGERS.CREATE_SUCCESS');
        } catch (e) {
            switch (e.status) {
                case 409:
                    this.$notifyErrorSimplified('MANAGERS.CREATE_DUPLICATE');
                    break;
                default:
                    this.$notifyErrorSimplified('MANAGERS.CREATE_ERROR');
                    break;
            }
        } finally {
            this.isLoading = false;
        }
    }

    private async updateManager() {
        try {
            await this.updateManagerAction(this.managerCopy);
            this.dismiss(true);
            this.$notifySuccessSimplified('MANAGERS.EDIT_SUCCESS');
        } catch (_) {
            this.$notifyErrorSimplified('MANAGERS.EDIT_ERROR');
        } finally {
            this.isLoading = false;
        }
    }

    public dismiss(reloadData: boolean = false) {
        this.$emit('closeDialog', reloadData);
    }
}

